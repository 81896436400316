import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Profile from "../models/profile";
import logo from "../assets/logo.svg";
import "../styles/employeeCard.css";
import Dots from "../components/Dots";
import AddToContacts from "../components/AddToContacts";
import { TailSpin } from "react-loader-spinner";

const EmployeeCard = () => {
    const params = useParams();
    const employeeId = params.employeeId;
    const navigate = useNavigate();
    const [profile, setProfile] = useState<Profile>();

    const isValidGuid = (id: any) => {
        if (!id) return false;
        if (!id?.match("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$")) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        async function fetchProfile() {
            if (!isValidGuid(employeeId)) {
                navigate("/");
            }
            const getProfile = await axios.get(`/employee/GetProfile?userId=${employeeId}`, {
                baseURL: `${process.env.REACT_APP_BASE_URL}`,
            });
            const result = await getProfile.data;
            setProfile(result);
        }
        fetchProfile();
    }, []);

    return (
        <>
            {profile ? (
                <div id="card">
                    <section id="card-header">
                        <img src={logo} alt="" className="logo" />
                        <Dots />
                    </section>
                    <hr className="horizontal-line"></hr>
                    <section id="card-info">
                        <div id="card-content">
                            <div className="card-info">
                                <div id="employee-main-details">
                                    <span id="name">{profile?.fullname}</span>
                                    <span id="function">{profile?.function}</span>
                                </div>
                                <div id="employee-details">
                                    <span>
                                        <a href={`mailto:${profile?.email}`}>{profile?.email}</a>
                                    </span>
                                    <div className="phone-section">
                                        <span>{profile?.mobilePhone}</span>
                                        <span>{profile?.officePhone}</span>
                                        <span>
                                            <a href="https://lantis.be/" target="_blank">
                                                lantis.be
                                            </a>
                                        </span>
                                    </div>
                                    <span id="lantis-address">{profile?.lantisAddress}</span>
                                </div>
                            </div>
                            <div className="vertical-line"></div>
                        </div>
                        <AddToContacts profile={profile} />
                    </section>
                </div>
            ) : (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                    <TailSpin height="100" width="100" color="#92d2c5" ariaLabel="loading" />
                </div>
            )}
        </>
    );
};

export default EmployeeCard;
