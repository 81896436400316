import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import EmployeeCard from './components/EmployeeCard';
import './styles/index.css';

ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<App />} />
            <Route path=':employeeId' element={<EmployeeCard />} />
        </Routes>
    </BrowserRouter>,
    document.getElementById('root')
);
