import Profile from '../models/profile';

export const useVcard = () => {
    const createVcard = (profile: Profile) => {
        if (profile) {
            window.location.href = `${process.env.REACT_APP_BASE_URL}/employee/DownloadVcard?userId=${profile.id}`;
        }
    };

    return { createVcard };
};

export default useVcard;
