import '../styles/dots.css';

const Dots = () => {
    return (
        <div className='dots'>
            <span className='dot' />
            <span className='dot' />
            <span className='dot' />
            <span className='dot' />
            <span className='dot' />
            <span className='dot' />
            <span className='dot' />
            <span className='dot' />
        </div>
    );
};

export default Dots;
